<template>
  <v-container class="my-4 py-6">
    <h2>Tabulador de envíos:</h2>
    <v-row
      class="px-2 mx-4 my-6"
      justify="center"
    >
      <v-card
        class="px-4 py-4"
        max-width="800"
      >
        <p class="text-primary">
          Agregar Nuevo tabulador:
        </p> 
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="nuevoTabulador.nombre_tabulador"
                label="Nombre del tabulador"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols=12
              md="4"
            >
              <v-text-field
                v-model="nuevoTabulador.precio"
                type="number"
                label="Precio"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols=12
              md="4"
            >
              <v-text-field
                v-model="nuevoTabulador.rango_en_km"
                type="number"
                label="Rango en km"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols=12
              md="6"
            >
              <v-text-field
                v-model="nuevoTabulador.volumetrico_inferior"
                type="number"
                label="Mínimo de peso volumétrico"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols=12
              md="6"
            >
              <v-text-field
                v-model="nuevoTabulador.volumetrico_superior"
                type="number"
                label="Máximo de peso volumétrico"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="nuevoTabulador.id_tipo_envio"
                :rules="[rules.required]"
                label="Tipo de envío"
                :items="tipoEnvios"
                item-text="dato"
                item-value="id"
                dense
                solo
                :disabled="editing"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="nuevoTabulador.id_moneda"
                :rules="[rules.required]"
                label="Tipo de moneda"
                :items="currency"
                item-text="nombre_moneda"
                item-value="id"
                dense
                solo
                disabled
              ></v-select>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="enviarTabulador"
              >
                {{ buttonAction }}
              </v-btn>
              <v-btn
                color="primary"
                @click="cleanForm"
              >
                limpiar campos 
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
    <v-row
      class="my-4"
      justify="center"
    >
      <v-card
        v-for="tabulador in tabuladoresEnvio"
        :key="tabulador.id"
        max-width="300"
        class="mx-4 my-4"
      >
        <v-card-text>
          <p class="display-1 text--primary">
            {{ tabulador.nombre }}
          </p>
          <div>
            Rango en KM: {{ tabulador.rango_en_km }}<br>
            Volumetrico inferior: {{ tabulador.volumetrico_inferior }}<br>
            Volumetrico superior: {{ tabulador.volumetrico_superior }}<br>
            Precio: $ {{ tabulador.precio }} {{tabulador.moneda.codigo_iso}}<br>
            Tipo de envio: {{ tabulador.tipo_envio.dato }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="edit(tabulador)" 
          >
            Editar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="deleteShippingTab(tabulador.id)"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from '@/mixins/formsValidations.js'
import { ApiAuth } from '@/api_utils/axios-base'

export default {
  metaInfo: {
    title: 'Tabulador de envíos'
  },
  mixins: [validationMixin],
  data: () => {
    return {
      nuevoTabulador: {
        nombre_tabulador: "",
        id_tipo_envio: null,
        id_distribuidor: null,
        rango_en_km: 0,
        volumetrico_inferior: 0,
        volumetrico_superior: 0,
        precio: 0,
        id_moneda: 1,
      },
      tipoEnvios: [],
      editing: false,
      valid: true,
      currency: [
        {id: 1, nombre_moneda: "Peso Mexicano"}
      ],
      tabuladoresEnvio: [],
    }
  },
  computed: {
    ...mapState('usuario', ['userData']),
    buttonAction() {
      return this.editing ? "Modificar" : "Agregar"
    },
  },
  methods: {
    async getTabuladoresEnvio() {
      const response = await ApiAuth.get(`/checkout/api/tabulador-envios/?distribuidor_id=${this.userData.distribuidorId}`)
      const responseData = await response.data
      this.tabuladoresEnvio = responseData

      const userDataToUpdate = {...this.userData}
      if (this.tabuladoresEnvio.length) {
        userDataToUpdate.hasTabs = true
        this.$store.commit('usuario/updateUserData', userDataToUpdate, { root: true})
      }
    },
    async getTipoEnvios() {
      try {
        let tipoEnvioResp = await ApiAuth.get('/checkout/api/tipo-envios/')
        let tipoEnvios = await tipoEnvioResp.data
        this.tipoEnvios = tipoEnvios
      } catch(error) {
        console.error("error getting tipo de envíos")
      }
    },
    cleanForm() {
      this.$refs.form.reset()
      //Object.keys(this.nuevoTabulador).forEach(k => this.nuevoTabulador[k] = null)
      this.editing = false
    },
    async enviarTabulador() {
      if(!this.$refs.form.validate()) {
        return
      }
      try {
        if(this.editing) {
          this.$store.commit('setLoading', true)
          this.editShippingTab()
          setTimeout(() => {
            this.$store.commit('setLoading', false)
          }, 500)
        } else {
          this.$store.commit('setLoading', true)
          this.nuevoTabulador.id_distribuidor = this.userData.distribuidorId
          // console.log("final tabulador", this.nuevoTabulador)
          let nuevoTabulador = await ApiAuth.post('/checkout/crear-tabulador', this.nuevoTabulador)
          // console.log("creado??", nuevoTabulador)
          setTimeout(() => {
            this.$store.commit('setLoading', false)
            //volver a traer los tabuladores para mostrarlos
          }, 500)
        }
      } catch(error) {
        console.error("error creating new tabulador")
      }
    },
    async editShippingTab() {
      const response = await ApiAuth.put(`/checkout/api/tabulador-envios/${this.nuevoTabulador.id}/`,
        {
          nombre: this.nuevoTabulador.nombre_tabulador,
          rango_en_km: this.nuevoTabulador.rango_en_km,
          volumetrico_inferior: this.nuevoTabulador.volumetrico_inferior,
          volumetrico_superior: this.nuevoTabulador.volumetrico_superior,
          precio: this.nuevoTabulador.precio    
        })
      const responseData =  await response.data
    },
    async deleteShippingTab(tabId) {
      this.$store.commit('setLoading', true)
      const response = await ApiAuth.delete(`/checkout/api/tabulador-envios/${tabId}/`)
      // console.log("Response: ", response);
      setTimeout(() => {
        this.$store.commit('setLoading', false)
      }, 500)
    },
    edit(tab) {
      this.nuevoTabulador = {
        id: tab.id,
        nombre_tabulador: tab.nombre,
        id_tipo_envio: tab.tipo_envio.id,
        id_distribuidor: tab.distribuidor.id,
        rango_en_km: tab.rango_en_km,
        volumetrico_inferior: tab.volumetrico_inferior,
        volumetrico_superior: tab.volumetrico_superior,
        precio: tab.precio,
        id_moneda: tab.moneda.id
      }
      this.editing = true
    }
  },
  created() {
    this.getTipoEnvios()
    this.getTabuladoresEnvio()
  }
}
</script>